import './App.css';
import Navbar from './Navbar.js';
//import Gallery from './Gallery.js';
import './Gallery.css';
import openInNewTab from './Components.js';
import ScrollProgressBar from './Progressbar.js';

function App() {
  return (
    <div className="App">
      <div className="App-Nav">
        <Navbar />
      </div>
      <ScrollProgressBar />
      
      <div className="App-scroll-container">
        <div className="App-header" id="home">
          <h1 className="grad-color">Create AI World</h1>
          <p>
            Host a collection of Simple AI-based Games & Apps
          </p>
          <a href="#gallery">
            see gallery ->
          </a>
        </div>
        <div className="App-header" id="gallery">
          <div className="gallery">
            <div className="text">
            <h1>Crash-AI</h1>
            <p>A simple text-based game to crash a chatbot’s logics</p>
            <button className="btn-grad" onClick={() => openInNewTab("https://games.createaiworld.com/crash-ai")}>Play</button>
            </div>
            <div className="img">
              {/*<img src="bot.png" />*/}
            </div>
          </div>
        </div>
        <div className="App-header" id="final">
          <div className="gallery">
            <h1>Stay tune to more releases</h1>
          </div>
        </div>
      </div>
    
    </div>
  );
}

export default App;
