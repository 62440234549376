import React, { useState, useEffect } from 'react';
import './App.css';

function ScrollProgressBar() {
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const box = document.querySelector(".App-scroll-container");
    const handleScroll = () => {
      const totalScrollHeight = box.scrollHeight - box.clientHeight;
      const currentScrollPosition = box.scrollTop;
      const progress = (currentScrollPosition / totalScrollHeight) * 100;
      setScrollProgress(progress);
    };

    box.addEventListener('scroll', handleScroll);
    return () => box.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="scroll-progress-bar" style={{ width: `${scrollProgress}%` }}></div>
  );
}

export default ScrollProgressBar;
