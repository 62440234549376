import React from 'react';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-left">
      </div>
      <div className="navbar-center">
        <ul className="nav-links">
          <li>
            <a href="#home">About</a>
          </li>
          {/*<li>
            <a href="#home" className="logo">
              Create AI World
            </a>
          </li>*/}
          <li>
            <a href="#gallery">Gallery</a>
          </li>
        </ul>
      </div>
      <div className="navbar-right">
      </div>
    </nav>
  );
};

export default Navbar;
